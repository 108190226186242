.hidden {
    display: none;
    /* visibility: "hidden" */
}

.branding {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.branding h1 {
    font-family: "Helvetica Neue";
    font-size: 1.5rem;
    margin: 0;
    letter-spacing: 7px;
}

@media (max-width: 768px) {
    .branding h1 {
        font-size: 1.0rem;
        margin-top: 10px;
    }
}

.branding h1 span {
    display: inline-block;
}